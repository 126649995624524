<template>
  <div
    class="tree-custom overflow-y-auto transition-custom"
    :class="collapsed ? 'w-[80px]' : 'w-[400px]'"
  >
    <div
      v-if="!isOptionsSelect"
      class="flex items-center gap-4 justify-between !mb-5"
    >
      <span
        class="uppercase text-[18px] font-semibold text-ems-gray200"
        v-if="!collapsed"
        >{{ t('group-management.title-tree') }}</span
      >
      <img
        :src="
          require(`@/static/img/icon/${
            collapsed ? 'nav-expand.png' : 'nav-collapse.png'
          }`)
        "
        alt="nav-icon"
        height="24"
        width="24"
        class="!h-[24px] !w-[24px] cursor-pointer"
        @click="toggleCollapse"
      />
    </div>
    <div
      v-if="!isOptionsSelect"
      class="flex pb-[32px] border-b-1 border-solid border-[#2e2e2e]"
      :class="!collapsed ? 'justify-between gap-4 items-center ' : 'flex-col'"
    >
      <a-input
        size="large"
        :placeholder="t('group-management.placeholder-input-search')"
        v-model:value="searchValue"
        class="w-100 ant-input-sm rounded-[8px] bg-ems-tag1 border-none"
        v-if="!collapsed"
        maxLength="255"
        @pressEnter="searchGroup"
        :allow-clear="true"
        @change="delaySearch"
      >
        <template #prefix>
          <img
            src="@/static/img/group-management/input-search.svg"
            alt="input-suffix"
            height="16"
            width="16"
          />
        </template>
        <template #suffix>
          <img
            src="@/static/img/group-management/input-suffix.svg"
            alt="input-suffix"
            height="20"
            width="20"
            @click="searchAdvanced"
            class="cursor-pointer"
          />
        </template>
      </a-input>
      <img
        src="@/static/img/group-management/btn-search.svg"
        alt="btn-search cursor-pointer"
        class="mb-[14px]"
        width="36"
        height="36"
        @click="toggleCollapse"
        v-else
      />
      <img
        v-if="checkHasPermission(perms, OBJECT_GROUP_PERM.CREATE)"
        src="@/static/img/group-management/create-icon.svg"
        alt="create-icon"
        width="36"
        height="36"
        @click="createGroup"
        class="cursor-pointer"
      />
    </div>
    <a-directory-tree
      :expanded-keys="expandedKeys"
      :auto-expand-parent="autoExpandParent"
      :tree-data="treeData"
      :selectedKeys="selectedKeys"
      @expand="onExpand"
      class="bg-transparent"
      :show-icon="false"
      v-if="!collapsed"
    >
      <template #title="{ dataRef, expanded }">
        <div
          class="group inline-flex justify-between items-center w-full py-[18px] transition-custom z-0"
          @click="onClickItem(dataRef)"
        >
          <a-popover
            v-if="
              checkHasPermission(perms, OBJECT_GROUP_PERM.CREATE_CHILD) ||
              checkHasPermission(perms, OBJECT_GROUP_PERM.DELETE) ||
              checkHasPermission(perms, OBJECT_GROUP_PERM.EDIT)
            "
            placement="bottomLeft"
            class="group-hover:inline-block hidden transition-custom"
            overlayClassName="popup-tree-menu"
          >
            <template #content>
              <div class="bg-ems-gray700 w-[240px] rounded-[8px]">
                <div
                  v-if="checkHasPermission(perms, OBJECT_GROUP_PERM.EDIT)"
                  class="tree-menu-item cursor-pointer py-3 inline-block rounded-t-[8px]"
                  @click="editGroup(dataRef)"
                >
                  <cds-feather-icons
                    type="edit"
                    size="24"
                    class="text-ems-gray100 rounded-full mr-3"
                  />
                  <span class="text-base text-ems-gray100">{{
                    t('group-management.edit-group')
                  }}</span>
                </div>
                <div
                  v-if="checkHasPermission(perms, OBJECT_GROUP_PERM.EDIT)"
                  class="w-full h-[1px] bg-ems-gray500"
                ></div>
                <div
                  v-if="checkHasPermission(perms, OBJECT_GROUP_PERM.DELETE)"
                  class="tree-menu-item cursor-pointer py-3 inline-block"
                  @click="showConfirmDelete(dataRef)"
                >
                  <cds-feather-icons
                    type="trash-2"
                    size="24"
                    class="text-ems-gray100 rounded-full mr-3"
                  />
                  <span class="text-base text-ems-gray100">{{
                    t('group-management.delete-group')
                  }}</span>
                </div>
                <div
                  v-if="checkHasPermission(perms, OBJECT_GROUP_PERM.DELETE)"
                  class="w-full h-[1px] bg-ems-gray500"
                ></div>
                <div
                  v-if="
                    checkHasPermission(perms, OBJECT_GROUP_PERM.CREATE_CHILD)
                  "
                  class="tree-menu-item cursor-pointer py-3 inline-block rounded-b-[8px]"
                  @click="createSubGroup(dataRef)"
                >
                  <cds-feather-icons
                    type="plus-circle"
                    size="24"
                    class="text-ems-gray100 rounded-full mr-3"
                  />
                  <span class="text-base text-ems-gray100">{{
                    t('group-management.add-sub-group')
                  }}</span>
                </div>
              </div>
            </template>
            <cds-feather-icons
              type="more-vertical"
              size="24"
              class="text-ems-gray400"
              v-if="!isOptionsSelect"
            />
          </a-popover>
          <!-- hiển thị icon theo độ ưu tiên: khẩn cấp: 1, cao 2, trung bình 3, thấp 4 -->
          <img
            :src="
              require(`@/static/img/group-management/${
                WARNING_LEVEL[dataRef.warningLevel - 1].img
              }`)
            "
            alt="priority"
          />
          <div class="text-base text-ems-gray300 flex-1 mx-4 title-custom z-0">
            <span
              v-if="
                dataRef.name.toUpperCase().indexOf(searchValue.toUpperCase()) >
                -1
              "
            >
              {{
                dataRef.name.substr(
                  0,
                  dataRef.name.toUpperCase().indexOf(searchValue.toUpperCase())
                )
              }}
              <span class="text-ems-white font-semibold">{{
                searchValue
              }}</span>
              {{
                dataRef.name.substr(
                  dataRef.name
                    .toUpperCase()
                    .indexOf(searchValue.toUpperCase()) + searchValue.length
                )
              }}
            </span>
            <span v-else>{{ dataRef.name }}</span>
          </div>
          <img
            v-if="checkHasPermission(perms, OBJECT_GROUP_PERM.CREATE_CHILD)"
            src="@/static/img/group-management/create-icon.svg"
            alt="create-icon"
            width="18"
            height="18"
            class="group-hover:inline-block hidden mr-[14px] rounded-full transition-custom"
            @click.stop="createSubGroup(dataRef)"
          />
          <cds-feather-icons
            v-if="dataRef.children && dataRef.children.length > 0"
            :type="expanded ? 'chevron-up' : 'chevron-down'"
            size="24"
            class="text-ems-gray600 group-hover:text-ems-gray400"
            @click.stop="!expanded ? onExpand(dataRef) : onCollapse(dataRef)"
          />
        </div>
      </template>
    </a-directory-tree>
  </div>
  <cds-modal
    :visible="visible"
    :mask-closable="false"
    hide-footer
    @onCancel="visible = false"
    class="bg-ems-gray800 pb-0"
  >
    <ModalConfirm
      ref="modal"
      @confirm="deleteGroup"
      @onClose="visible = false"
      :title="
        t('group-management.confirm-delete', {
          name: selectedGroupToDel.name,
        })
      "
    ></ModalConfirm>
  </cds-modal>
</template>
<script setup>
import { computed, onMounted, ref, inject, watch, onUnmounted } from 'vue';
import { i18n } from '@/main';
import { useStore } from 'vuex';
import { ACTION, WARNING_LEVEL } from '@/config/Constant.js';
import ModalConfirm from '@/components/modal-confirm/ModalConfirm.vue';
import { useRoute, useRouter } from 'vue-router';
import { debounce } from 'lodash';
import { trim, checkHasPermission } from '@/util/common-utils';
import { OBJECT_GROUP_PERM } from '@/util/common-constant';

const route = useRoute();
const { t } = i18n.global;
const eventBus = inject('eventBus');
const expandedKeys = ref([]);
const { state, dispatch } = useStore();
const searchValue = ref('');
const selectedKeys = ref([]);
let selectedGroup = computed(() => state.ioGroup.selectedGroup);
const visible = ref(false);
const autoExpandParent = ref(false);
const DEBOUNCE_TIME = 300;
const props = defineProps({
  isOptionsSelect: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits([
  'create-group',
  'create-sub-group',
  'search-advanced',
]);
const perms = computed(() => state.auth.userInfo.permissions);
let treeData = computed(() => state.ioGroup.treeGroup);
const treeFlat = computed(() => state.ioGroup.groups);
const onClickItem = async (dataRef) => {
  await dispatch('ioGroup/changeAction', ACTION.VIEW);
  await dispatch('ioGroup/selectGroup', dataRef.id);
  eventBus.emit('tree-sidebar-change-selected-group');
};
const delaySearch = debounce(() => {
  searchGroup();
}, DEBOUNCE_TIME);
const onExpand = (data) => {
  expandedKeys.value.push(data.key);
  autoExpandParent.value = false;
};

const searchAdvanced = () => {
  emits('search-advanced');
};

const onCollapse = (data) => {
  expandedKeys.value.splice(expandedKeys.value.indexOf(data.key), 1);
  autoExpandParent.value = false;
};

const createSubGroup = (group) => {
  emits('create-sub-group', group);
};

const collapsed = ref(false);
const toggleCollapse = () => {
  collapsed.value = !collapsed.value;
};
const createGroup = () => {
  emits('create-group');
};
const editGroup = async (data) => {
  await dispatch('ioGroup/changeAction', ACTION.EDIT);
  await dispatch('ioGroup/selectGroup', data.id);
  eventBus.emit('tree-sidebar-change-selected-group', true);
};
const deleteGroup = async () => {
  await dispatch('ioGroup/delete', selectedGroupToDel.value);
  await dispatch('ioGroup/getTreeGroup');
  visible.value = false;
};

const selectedGroupToDel = ref(null);

const showConfirmDelete = (data) => {
  selectedGroupToDel.value = data;
  visible.value = true;
};
watch(selectedGroup, (newValue) => {
  setSelectedKey(newValue);
});
onMounted(async () => {
  await dispatch('ioGroup/getAllGroup');
  eventBus.on('tree-sidebar-search-group', async (data) => {
    searchValue.value = data.name;
    await dispatch('ioGroup/getTreeGroup', data);
    if (data.warning_lvl) {
      afterSearchGroup(true);
    } else afterSearchGroup();
  });
  await dispatch('ioGroup/selectGroup', route.query.idGroup);
  if (route.query && route.query.action) {
    await dispatch('ioGroup/changeAction', Number(route.query.action));
  }
  setSelectedKey(selectedGroup.value);
});
watch(
  () => route.query.idGroup,
  async () => {
    await dispatch('ioGroup/selectGroup', route.query.idGroup);
    if (route.query && route.query.action) {
      await dispatch('ioGroup/changeAction', Number(route.query.action));
    }
    setSelectedKey(selectedGroup.value);
  },
  {
    immediate: true,
    deep: true,
  }
);
onUnmounted(() => {
  eventBus.off('tree-sidebar-search-group');
});
const setSelectedKey = (newValue) => {
  if (newValue) {
    selectedKeys.value = [newValue.id];
    autoExpandParent.value = false;
    setTimeout(() => {
      expandedKeys.value.push(newValue.id);
      expandedKeys.value.push(newValue.parentId);
      autoExpandParent.value = true;
    }, 0);
  } else {
    selectedKeys.value = [];
  }
};

const searchGroup = async () => {
  await dispatch('ioGroup/getTreeGroup', { name: trim(searchValue.value) });
  afterSearchGroup(false);
};

const afterSearchGroup = (isSearchByWarningLvl = false) => {
  expandedKeys.value = treeFlat.value
    .map((item) => {
      if (isSearchByWarningLvl) {
        if (
          item.name.toUpperCase().indexOf(searchValue.value.toUpperCase()) >
            -1 ||
          item.warningLevel === props.warningLvl
        ) {
          if (item.parentId) return item.parentId;
        }
      } else {
        if (
          item.name.toUpperCase().indexOf(searchValue.value.toUpperCase()) > -1
        ) {
          if (item.parentId) return item.parentId;
        }
      }
      return null;
    })
    .filter((item, i, self) => item && self.indexOf(item) === i);
  autoExpandParent.value = true;
};
</script>
<style lang="scss">
.transition-custom {
  transition: all 0.3s linear;
}
.tree-custom {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
  height: 100%;
  padding: 1.25rem;
}
.tree-custom .ant-input-sm input.ant-input {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
.tree-custom .ant-input-sm input.ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
.tree-custom .ant-tree-list .ant-tree-switcher {
  display: none;
}
.tree-custom .ant-tree-list .ant-tree-treenode {
  padding: 0px;
  width: 100%;
  border-bottom: 1px solid #2e2e2e;
}
.tree-custom .ant-tree-list .ant-tree-treenode:before {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
  height: 100%;
}
.tree-custom .ant-tree-list .ant-tree-treenode:hover:before {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
}
.tree-custom .ant-tree-list .ant-tree-treenode .ant-tree-node-content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;
}
.tree-custom .ant-tree-list .ant-tree-treenode .ant-tree-node-content-wrapper .ant-tree-title {
  width: 100%;
}
.tree-custom .ant-tree-list .ant-tree-treenode .ant-tree-node-selected {
  background-color: #434343 !important;
}
.popup-tree-menu .ant-popover-inner {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
  border-radius: 8px;
  padding: 0px;
  box-shadow: 0 4px 84px 0 rgba(0, 0, 0, 1);
}
.popup-tree-menu .ant-popover-arrow-content {
  background-color: #434343 !important;
}
.popup-tree-menu .tree-menu-item:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(123, 123, 123, var(--tw-bg-opacity));
}
.popup-tree-menu .tree-menu-item {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  padding-left: 1rem;
  padding-right: 1rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
}
</style>
