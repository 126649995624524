<template>
  <cds-main class="list-info h-full flex-1">
    <div class="!pt-5 border-b-1 border-solid border-ems-tag1">
      <a-tabs v-model:activeKey="activeTab" @change="onChangeTab($event)">
        <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.tabName">
        </a-tab-pane>
      </a-tabs>
    </div>
    <div class="w-full component-custom">
      <Suspense>
        <template #default>
          <component
            :is="component"
            :selectedGroup="selectedGroup"
            v-if="selectedGroup"
            :key="selectedGroup.id"
            @create-io="createIO"
          />
          <div v-else class="h-full w-full flex items-center justify-center">
            <cds-feather-icons
              type="alert-circle"
              size="28"
              class="text-ems-white mr-4"
            />
            <span class="text-base text-ems-white">{{
              t('group-management.select-group-noti')
            }}</span>
          </div>
        </template>
        <template #fallback>
          <cds-loading />
        </template>
      </Suspense>
    </div>
  </cds-main>
</template>
<script setup>
import {
  defineAsyncComponent,
  ref,
  computed,
  inject,
  onMounted,
  onUnmounted,
} from 'vue';
import { i18n } from '@/main';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const { t } = i18n.global;
const GroupInfo = defineAsyncComponent(() => import('./GroupInfo.vue'));
const ListObjects = defineAsyncComponent(() => import('./ListObjects.vue'));
const component = ref(ListObjects);
const eventBus = inject('eventBus');
const route = useRoute();
const router = useRouter();
const { state, dispatch } = useStore();
let selectedGroup = computed(() => state.ioGroup.selectedGroup);
let action = computed(() => state.ioGroup.action);

const emits = defineEmits(['create-io']);

onMounted(() => {
  eventBus.on('tree-sidebar-change-selected-group', (isEdit = false) => {
    if (isEdit) {
      onChangeTab('group-info');
    } else onChangeTab('list-objects');
  });
  if (route.query.tab) {
    onChangeTab(route.query.tab);
  }
});
onUnmounted(() => {
  eventBus.off('tree-sidebar-change-selected-group');
});
const tabs = [
  {
    key: 'list-objects',
    tabName: t('group-management.list-objects'),
    component: ListObjects,
  },
  {
    key: 'group-info',
    tabName: t('group-management.group-info'),
    component: GroupInfo,
  },
];
const createIO = () => {
  emits('create-io');
};
const onChangeTab = (key) => {
  activeTab.value = key;
  tabs.forEach((item) => {
    if (item.key === activeTab.value) {
      component.value = item.component;
    }
  });
  let query = { ...route.query, tab: key };
  if (selectedGroup.value) {
    query.idGroup = selectedGroup.value.id;
    query.action = action.value;
  }
  router.push({
    path: '/io-management/group-management',
    query: query,
  });
};
const activeTab = ref(route.query.tab ? route.query.tab : 'list-objects');
</script>
<style lang="scss">
.list-info {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
  padding: 1.25rem;
}
.list-info .ant-tabs-nav-list {
  -webkit-column-gap: 47px;
  -moz-column-gap: 47px;
  grid-column-gap: 47px;
  column-gap: 47px;
}
.list-info .ant-tabs .ant-tabs-ink-bar {
  display: block;
}
.list-info .ant-tabs .ant-tabs-tab {
  padding-bottom: 23px;
  padding-left: 0px;
}
.list-info .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  text-transform: uppercase;
}
.list-info .component-custom {
  height: calc(100% - 88px);
}
</style>
