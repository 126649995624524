<template>
  <div class="p-[14px] h-full flex gap-4 list-groups">
    <TreeSidebar
      @create-group="createGroup"
      @create-sub-group="onCreateSubGroup"
      @search-advanced="openSearchAdvanced"
    />
    <List @create-io="createIO" />
    <div
      class="absolute top-[53%] right-0 z-60 icon-expand-drawer cursor-pointer"
      @click="openSearchAdvanced"
      v-if="isSave"
    />
  </div>
  <drawer-custom
    :visible="openDrawer"
    :width="450"
    @onClose="onClose"
    @onCancel="openDrawer = false"
    :component="component"
    :isShowHideBtn="isShowHideBtn"
    :title="title"
    :isCreateSubGroup="isCreateSubGroup"
    :parent="parent"
    :formState="formState"
    @onSearchAdvanced="onSearchAdvanced"
    @onHide="onHide"
  />
</template>
<script setup>
import TreeSidebar from '@/view/io-information/group-management/components/TreeSidebar.vue';
import List from '@/view/io-information/group-management/components/List.vue';
import { i18n } from '@/main';
import DrawerCustom from '@/components/drawer-custom/index.vue';
import {
  reactive,
  onMounted,
  ref,
  defineAsyncComponent,
  onUnmounted,
  inject,
} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { trim } from '@/util/common-utils';
const { t } = i18n.global;
const openDrawer = ref(false);
const { state, dispatch } = useStore();

const CreateGroupCpn = defineAsyncComponent(() => import('./CreateGroup.vue'));
const CreateIOCpn = defineAsyncComponent(() => import('./CreateIO.vue'));
const SearchObjectCpn = defineAsyncComponent(() =>
  import('./SearchObject.vue')
);
const component = ref(CreateGroupCpn);
const eventBus = inject('eventBus');
const parent = ref({});
const title = ref('');
const isSave = ref(false);
const isShowHideBtn = ref(false);
const isCreateSubGroup = ref(false);
const componentName = ref('');
const createGroup = () => {
  title.value = t('group-management.create-group-title');
  component.value = CreateGroupCpn;
  componentName.value = 'CreateGroupCpn';
  isCreateSubGroup.value = false;
  isShowHideBtn.value = false;
  isSave.value = false;
  openDrawer.value = true;
};
const onCreateSubGroup = (group) => {
  parent.value = group;
  title.value = t('group-management.create-sub-group-title');
  component.value = CreateGroupCpn;
  componentName.value = 'CreateGroupCpn';
  isCreateSubGroup.value = true;
  isShowHideBtn.value = false;
  isSave.value = false;
  openDrawer.value = true;
};
const createIO = () => {
  title.value = t('group-management.create-io-title');
  component.value = CreateIOCpn;
  componentName.value = 'CreateIOCpn';
  isShowHideBtn.value = false;
  isSave.value = false;
  openDrawer.value = true;
};
const openSearchAdvanced = () => {
  component.value = SearchObjectCpn;
  componentName.value = 'SearchObjectCpn';
  title.value = t('common.search');
  isShowHideBtn.value = true;
  openDrawer.value = true;
};
const onHide = () => {
  openDrawer.value = false;
  isSave.value = true;
};
const onClose = (isSaveData) => {
  if (!isSaveData) {
    resetForm();
  }
  isSave.value = isSaveData;
  openDrawer.value = false;
};
const resetForm = () => {
  formState = reactive({
    groupName: '',
    warningLvl: null,
    ioName: '',
    img: '',
  });
};
onMounted(() => {
  dispatch('ioGroup/getTreeGroup');
  dispatch('ioGroup/getAllGroup');
});
let formState = reactive({
  groupName: '',
  warningLvl: null,
  ioName: null,
  img: '',
});

const onSearchAdvanced = () => {
  if (componentName.value == 'SearchObjectCpn') {
    searchUser();
  } else if (componentName.value == 'CreateIOCpn') {
    onClose(false);
  } else if (componentName.value == 'CreateGroupCpn') {
    onClose(false);
  }
};
onUnmounted(() => {
  dispatch('ioGroup/selectGroup', null);
});
const router = useRouter();
const searchUser = () => {
  if (!formState.ioName && formState.img.length === 0) {
    eventBus.emit('tree-sidebar-search-group', {
      name: trim(formState.groupName),
      warning_lvl: formState.warningLvl,
    });
    onClose(true);
  } else {
    router.push({
      path: '/io-management/group-management/search',
      query: formState,
    });
  }
};
</script>
<style lang="scss">

.list-groups {
  .icon-expand-drawer {
    background-image: url('../../../../static/img/icon/open-drawer.svg');
    background-size: cover;
    height: 67px;
    width: 19px;
    &:hover {
      background-image: url('../../../../static/img/icon/open-drawer-active.svg');
    }
  }
}

</style>
